<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Season
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="season-form">
          <v-text-field
            label="Start Date *"
            v-model="fields.start_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('start_date')"
            :error-messages="errors['start_date']"
          ></v-text-field>
          <v-text-field
            label="End Date *"
            v-model="fields.end_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('end_date')"
            :error-messages="errors['end_date']"
          ></v-text-field>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="season-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      season: {},
      fields: {
        start_date: null,
        end_date: null,
        status: null,
      },
      statuses: ["Past", "Current", "Future"],
      errors: {},
    };
  },

  methods: {
    open(season = null) {
      if (season !== null) {
        this.season = season;
        this.isEditing = true;
        this.fields.start_date = season.start_date;
        this.fields.end_date = season.end_date;
        this.fields.status = season.status;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.seasonId = this.season.id;
      }

      this.$store
        .dispatch("craigtoun/venuesStore/saveSeason", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.season = {};
      this.fields = {
        start_date: null,
        end_date: null,
        status: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
