<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Venue Seasons</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues' }"
                >
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-tooltip bottom v-if="user.roles[0].is_superadmin == true">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.seasonDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Season</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="seasons"
        no-data-text="No Seasons Found"
      >
        <template v-slot:item.start_date="{ item }">
          {{ item.formatted_dates.start_date }}
        </template>
        <template v-slot:item.end_date="{ item }">
          {{ item.formatted_dates.end_date }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            color="red white--text"
            v-if="item.status === 'Past'"
            >Past</v-chip
          >
          <v-chip
            label
            small
            color="green white--text"
            v-else-if="item.status === 'Current'"
            >Current</v-chip
          >
          <v-chip label small color="blue white--text" v-else>Future</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.seasonDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <season-dialog ref="seasonDialog" />
    <v-dialog v-model="deleteSeason.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Season</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteSeason.season.start_date }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteSeason.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SeasonDialog from "./components/SeasonDialog.vue";

export default {
  components: {
    SeasonDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Seasons",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteSeason: {
        dialog: false,
        season: {},
        loading: false,
      },
    };
  },

  methods: {
    openDelete(season) {
      this.deleteSeason.season = season;
      this.deleteSeason.dialog = true;
    },

    resetDelete() {
      this.deleteSeason.dialog = false;
      this.deleteSeason.season = {};
      this.deleteSeason.loading = false;
    },

    saveDelete() {
      this.deleteSeason.loading = true;

      this.$store
        .dispatch("craigtoun/venuesStore/deleteSeason", {
          appId: this.$route.params.id,
          seasonId: this.deleteSeason.season.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteSeason.loading = false;
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters["craigtoun/venuesStore/user"];
    },

    seasons() {
      return this.$store.getters["craigtoun/venuesStore/seasons"];
    },
  },
};
</script>
